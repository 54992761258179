import React, { useState } from 'react'
import {
    Box,
    Grid,
    Text,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Input,
    Flex,
} from '@chakra-ui/react'
import { ChevronRightIcon, LockIcon, UnlockIcon } from '@chakra-ui/icons'
import { Season, SeasonState } from '../data'
import { useConfigureSeason } from '../hooks/useConfigureSeason'
import { usePublishSeason } from '../hooks/usePublishSeason'
import { useLockSeason } from '../hooks/useLockSeason'
import { StyledModal } from '../components/StyledModal'
import { useGetRounds } from '../hooks/useGetRounds'
import { AddRoundsSection } from './AddRoundsSection'
import { ExpertTipperSection } from './ExpertTipperSection'
import { Fixtures } from './Fixtures'
import { SeasonPageName } from './SeasonForm'
import { ConfigureRounds } from './ConfigureRounds'
import { DocumentsAndPrizing } from './DocumentsAndPrizing'
import { ManagePrizing } from './ManagePrizing'
import { ManageWinners } from './ManageWinners'
import { ExpertTippers } from './ExpertTippers'
import { ImageUpload } from '../components/ImageUpload'
import { getSeasonState } from '../helpers/season-state'
import {
    ReminderList,
    TrackContent,
    TrackHeading,
} from '../components/SeasonTrackSections'
import { PrizeWinnersSection } from './PrizeWinnersSection'
import { CompetitionNameSection } from './CompetitionNameSection'
import { CompetitionDataSection } from './CompetitionDataSection'

function toTitleCase(sentence: string) {
    return sentence
        .replace('_', ' ')
        .split(' ')
        .map((w) => `${w[0].toUpperCase()}${w.slice(1)}`)
        .join(' ')
}

export interface ManageSeasonBaseProps {
    season: Season
    refetch: () => Promise<Season[] | undefined>
}

interface ManageSeasonProps extends ManageSeasonBaseProps {
    page?: SeasonPageName
}

export function ManageSeason({ season, page, refetch }: ManageSeasonProps) {
    const renderPage: Record<SeasonPageName, JSX.Element> = {
        add_rounds: <ConfigureRounds season={season} refetch={refetch} />,
        expert_tippers: <ExpertTippers season={season} refetch={refetch} />,
        prizing: <ManagePrizing season={season} refetch={refetch} />,
        winners: <ManageWinners season={season} refetch={refetch} />,
    }

    return (
        <Box px="10" maxW={'1800px'} marginX="auto" mb="24">
            <Box flexDir="row" display="flex">
                <Box flex="1">
                    <Breadcrumb
                        spacing="8px"
                        separator={<ChevronRightIcon color="gray.500" />}
                    >
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/seasons">
                                Manage Seasons
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem>
                            <BreadcrumbLink
                                href={`/seasons/${season.sport}_${season.season}`}
                            >
                                {season.sport} ({season.season})
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        {page && (
                            <BreadcrumbItem isCurrentPage>
                                <BreadcrumbLink
                                    href={`/seasons/${season.sport}_${season.season}/${page}`}
                                >
                                    {toTitleCase(page)}
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        )}
                    </Breadcrumb>

                    <Text fontSize={'xx-large'} mt="1">
                        {season.sport} ({season.season})
                    </Text>
                </Box>
                {/* <SeasonIdInformation season={season} refetch={refetch} /> */}
            </Box>

            <Box my="4">
                {page ? (
                    renderPage[page]
                ) : (
                    <ConfiguringBase season={season} refetch={refetch} />
                )}
            </Box>
        </Box>
    )
}

function ConfiguringBase({
    season,
    refetch,
}: {
    season: Season
    refetch: () => Promise<Season[] | undefined>
}) {
    const [publishSeason] = usePublishSeason()
    const [lockSeason] = useLockSeason()
    const { data: roundData } = useGetRounds({
        sport: season.sport,
        season: season.season,
    })

    interface ModalContent {
        title?: string
        body?: string
        confirmAction: () => void
    }

    const [configure] = useConfigureSeason()
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [modalContent, setModalContent] = useState<ModalContent | undefined>(
        undefined,
    )
    const [showLockModal, setShowLockModal] = useState(false)
    const [lockReason, setLockReason] = useState('')

    const seasonState = getSeasonState(season)

    const canPublish =
        !!season.config?.prizing.length &&
        season.config?.termsAndConditions !== ''

    interface ButtonComponents {
        text: string
        confirmTitle?: string
        confirmBody?: string
        action?: () => void
        inactive?: boolean
    }

    function ProgressButtons({
        backButton,
        progButton,
    }: {
        backButton?: ButtonComponents
        progButton?: ButtonComponents
    }) {
        function populateModal(action, title, body) {
            if (!action) return

            setModalContent({
                title: title,
                body: body,
                confirmAction: async () => {
                    await action()
                    await refetch()
                    setShowConfirmModal(false)
                },
            })

            setShowConfirmModal(true)
        }

        return (
            <Flex gap="4" justify="right" width="100%">
                {backButton ? (
                    <Button
                        onClick={() =>
                            populateModal(
                                backButton.action,
                                backButton.confirmTitle,
                                backButton.confirmBody,
                            )
                        }
                        colorScheme="gray"
                        disabled={!backButton.action || backButton.inactive}
                    >
                        {backButton.text}
                    </Button>
                ) : null}
                {progButton ? (
                    <Button
                        onClick={() =>
                            populateModal(
                                progButton.action,
                                progButton.confirmTitle,
                                progButton.confirmBody,
                            )
                        }
                        colorScheme={'blue'}
                        disabled={!progButton.action || progButton.inactive}
                    >
                        {progButton.text}
                    </Button>
                ) : null}
            </Flex>
        )
    }

    return (
        <>
            <Grid templateColumns="240px 1fr" rowGap="4">
                {/* Draft */}
                <TrackHeading
                    state="draft"
                    active={seasonState === 'draft'}
                    tooltip={getTooltips('draft')}
                ></TrackHeading>
                <TrackContent>
                    <DocumentsAndPrizing season={season} refetch={refetch} />
                    <ReminderList
                        {...getReminderList('draft')}
                        defaultClosed={seasonState !== 'draft'}
                    />
                    {seasonState === 'draft' ? (
                        <ProgressButtons
                            progButton={{
                                text: 'Progress to PUBLISHED',
                                action: () =>
                                    publishSeason({
                                        season: {
                                            ...season,
                                            published: true,
                                        },
                                    }),
                                confirmTitle:
                                    'Are you sure you want to publish this season?',
                                inactive: !canPublish,
                            }}
                        />
                    ) : null}
                </TrackContent>
                {/* Published */}
                <TrackHeading
                    state="published"
                    active={seasonState === 'published'}
                    tooltip={getTooltips('published')}
                ></TrackHeading>
                <TrackContent>
                    <Flex gap="5">
                        <CompetitionNameSection
                            season={season}
                            refetch={refetch}
                        />
                        <Box>
                            <Text fontSize={'x-large'}>
                                Official Social Share Sponsor
                            </Text>
                            <Text fontSize={'sm'}>
                                <i>*Optional. </i>Image must be <b>png</b>{' '}
                                format, 100x100px
                            </Text>
                            <ImageUpload
                                fileName={`${season.sport}-socialsharesponsor.png`}
                            ></ImageUpload>
                        </Box>
                    </Flex>
                    <ReminderList
                        {...getReminderList('published')}
                        defaultClosed={seasonState !== 'published'}
                    />
                    {seasonState === 'published' ? (
                        <ProgressButtons
                            backButton={{
                                text: 'Back to DRAFT',
                                action: () =>
                                    publishSeason({
                                        season: {
                                            ...season,
                                            published: false,
                                        },
                                    }),
                                confirmTitle:
                                    'Are you sure you want to unpublish this season?',
                                confirmBody:
                                    'Ensure you have approval before unpublishing this way',
                            }}
                            progButton={{
                                text: 'Progress to EARLY-BIRD',
                                action: () => {
                                    if (season.config) {
                                        configure({
                                            season,
                                            config: {
                                                ...season.config,
                                                launchType: 'Full Launch',
                                            },
                                        })
                                    }
                                },
                                confirmTitle: 'Move season to EARLY-BIRD?',
                            }}
                        />
                    ) : null}
                </TrackContent>
                {/* Early-bird */}
                <TrackHeading
                    state="early-bird"
                    active={seasonState === 'early-bird'}
                    tooltip={getTooltips('early-bird')}
                ></TrackHeading>
                <TrackContent>
                    <ExpertTipperSection season={season} refetch={refetch} />
                    <CompetitionDataSection season={season} refetch={refetch} />
                    {season.sport === 'Cricket' ? (
                        <AddRoundsSection
                            season={season}
                            rounds={roundData?.rounds}
                        />
                    ) : null}
                    <Fixtures season={season} />
                    <ReminderList
                        {...getReminderList('early-bird')}
                        defaultClosed={seasonState !== 'early-bird'}
                    />
                    {seasonState === 'early-bird' ? (
                        <ProgressButtons
                            progButton={{
                                text: 'Progress to IN-SEASON is automatic',
                            }}
                            backButton={{
                                text: 'Revert to PUBLISHED',
                                action: () => {
                                    if (season.config) {
                                        configure({
                                            season,
                                            config: {
                                                ...season.config,
                                                launchType: 'Soft Launch',
                                            },
                                        })
                                    }
                                },
                                confirmTitle: 'Revert season to PUBLISHED?',
                            }}
                        />
                    ) : null}
                </TrackContent>
                {/* In-season */}
                <TrackHeading
                    state="in-season"
                    active={seasonState === 'in-season'}
                    tooltip={getTooltips('in-season')}
                ></TrackHeading>
                <TrackContent>
                    <PrizeWinnersSection season={season} />
                    <ReminderList {...getReminderList('in-season')} />
                    {seasonState === 'in-season' ? (
                        <ProgressButtons
                            progButton={{
                                text: 'Progress to COMPLETED is automatic',
                            }}
                        />
                    ) : null}
                </TrackContent>
                {/* Completed */}
                <TrackHeading
                    state="completed"
                    active={seasonState === 'completed'}
                    tooltip={getTooltips('completed')}
                ></TrackHeading>
                <TrackContent>
                    <ReminderList
                        {...getReminderList('completed')}
                        defaultClosed={seasonState !== 'completed'}
                    />
                    {seasonState === 'completed' ? (
                        <ProgressButtons
                            progButton={{
                                text: 'Progress to ARCHIVED',
                                action: () =>
                                    publishSeason({
                                        season: {
                                            ...season,
                                            published: false,
                                        },
                                    }),
                                confirmTitle:
                                    'Are you sure you want to archive this season?',
                                confirmBody: `Ensure at least one other ${season.sport} season is published before archiving this one`,
                            }}
                        />
                    ) : null}
                </TrackContent>
                {/* Archived */}
                <TrackHeading
                    state="archived"
                    active={seasonState === 'archived'}
                    tooltip={getTooltips('archived')}
                ></TrackHeading>
                <TrackContent>
                    <ReminderList {...getReminderList('archived')} />
                    {seasonState === 'archived' ? (
                        <ProgressButtons
                            backButton={{
                                text: 'Revert to COMPLETED',
                                action: () =>
                                    publishSeason({
                                        season: {
                                            ...season,
                                            published: true,
                                        },
                                    }),
                                confirmTitle:
                                    'Are you sure you want to republish this season?',
                                confirmBody:
                                    'This is an abnormal action, double check before confirming',
                            }}
                        />
                    ) : null}
                </TrackContent>
            </Grid>
            <Flex mt="14" justify={'center'}>
                <Button
                    mt={'10'}
                    onClick={() => setShowLockModal(true)}
                    leftIcon={season.locked ? <UnlockIcon /> : <LockIcon />}
                >
                    {season.locked ? 'Unlock' : 'Lock'} Season
                </Button>
            </Flex>
            <StyledModal
                size="lg"
                onClose={() => setShowConfirmModal(false)}
                isOpen={showConfirmModal}
                footer={
                    <Flex gap="4">
                        <Button
                            onClick={() => setShowConfirmModal(false)}
                            colorScheme="white"
                            variant="outline"
                        >
                            Cancel
                        </Button>
                        <Button
                            colorScheme="red"
                            onClick={modalContent?.confirmAction}
                        >
                            Confirm
                        </Button>
                    </Flex>
                }
            >
                <Flex flexDir="column" gap="5" px="4" pt="2" textAlign="center">
                    <Text fontSize="xl" fontWeight="bold">
                        {modalContent?.title}
                    </Text>
                    {modalContent?.body ? (
                        <Text fontSize="md">
                            {/* {`Ensure at least one other ${season.sport} season is published before unpublishing this one`} */}
                            {modalContent.body}
                        </Text>
                    ) : undefined}
                </Flex>
            </StyledModal>
            <StyledModal
                size="lg"
                onClose={() => setShowLockModal(false)}
                isOpen={showLockModal}
                footer={
                    <Box>
                        <Button
                            onClick={() => setShowLockModal(false)}
                            colorScheme="white"
                            variant="outline"
                        >
                            Cancel
                        </Button>
                        <Button
                            ml="4"
                            colorScheme="red"
                            disabled={lockReason.length < 8}
                            onClick={async () => {
                                await lockSeason({ season, reason: lockReason })
                                await refetch()
                                setShowLockModal(false)
                            }}
                            leftIcon={
                                season.locked ? <UnlockIcon /> : <LockIcon />
                            }
                        >
                            {season.locked ? 'Unlock' : 'Lock'}
                        </Button>
                    </Box>
                }
            >
                <Box pt="5" textAlign="center">
                    <Text fontSize="large">
                        Reason for {season.locked ? 'unlocking' : 'locking'}{' '}
                        season?
                    </Text>
                    <Text fontSize="small">Minimum 8 characters</Text>
                </Box>
                <Input
                    mt={'5'}
                    title="Reason"
                    value={lockReason}
                    onChange={(e) => setLockReason(e.target.value)}
                />
            </StyledModal>
        </>
    )
}

function getReminderList(state: SeasonState) {
    switch (state) {
        case 'draft':
            return {
                heading:
                    'Before you progress to PUBLISHED, ensure the following:',
                points: [
                    'Both Terms & Conditions, and Prizing have been configured',
                    'You have business approval to publish the season',
                ],
            }
        case 'published':
            return {
                heading:
                    'Before you progress to EARLY-BIRD, ensure the following:',
                points: [
                    'The Featured Images are updated (if needed)',
                    'The Log-In Screen text is updated (if needed)',
                    'The Sponsors are updated (if needed)',
                    'The Prize images are updated (if needed)',
                    'In-article Drivers in The West and Perthnow are updated (if needed)',
                    'Create the Expert Tippers competition (if needed)',
                ],
            }
        case 'early-bird':
            return {
                heading:
                    'Moving to IN-SEASON happens as soon as fixtures are ingested, before that happens ensure the following:',
                points: [
                    'Marketing has sent EDM announcing the beginning of the season',
                    'Update Log-In Screen text (if needed)',
                    'The Featured Images are updated (if needed)',
                    'In-article Drivers in The West and Perthnow are updated (if needed)',
                ],
            }
        case 'completed':
            return {
                heading:
                    'Before you progress to ARCHIVED, ensure the following:',
                points: [
                    'All competitions/prizes have been announced/awarded.',
                    'You have business approval.',
                ],
            }
        default:
            return {
                heading:
                    'Before you progress to PUBLISHED, ensure the following:',
                points: ['You have business approval to publish the season.'],
            }
    }
}

function getTooltips(state: SeasonState) {
    switch (state) {
        case 'draft':
            return {
                heading: 'DRAFT',
                subheading: 'When in DRAFT:',
                points: ['Users cannot see the season at all'],
            }
        case 'published':
            return {
                heading: 'PUBLISHED',
                subheading: 'When in PUBLISHED:',
                points: [
                    'Users will see the season if they navigate to them',
                    'Users can register for the competition',
                    'Users cannot tip',
                    'No alerts or popups will push users to the season',
                ],
            }
        case 'early-bird':
            return {
                heading: 'EARLY-BIRD',
                subheading: 'When in EARLY-BIRD, as PUBLISHED but:',
                points: [
                    'Popup modals will appear once for users to sign up for the season',
                ],
            }
        case 'in-season':
            return {
                heading: 'IN-SEASON',
                subheading: 'When in IN-SEASON, as EARLY-BIRD but:',
                points: [
                    'Users are able to tip on upcoming games',
                    'Game data automatically updates when games happen live',
                    'Weekly prizes should be announced and visible to users',
                ],
            }
        case 'completed':
            return {
                heading: 'COMPLETED',
                subheading: 'When in COMPLETED:',
                points: [
                    'Users cannot register for the competition',
                    'Users cannot tip (there are no upcoming games)',
                    'The results/leaderboard of the competition are displayed',
                ],
            }
        case 'archived':
            return {
                heading: 'ARCHIVED',
                subheading: 'When in ARCHIVED:',
                points: [
                    'The season is hidden, users cannot navigate to it',
                    'Naturally, users cannot register, tip, or see results of the season',
                ],
            }
        default:
            return {
                heading: 'Unknown State',
                subheading: 'No tooltips available for this state.',
                points: [],
            }
    }
}
